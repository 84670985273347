import React, { useEffect, useState } from 'react';
import { useProduto } from '~/pages/Produto/ProdutoContext';
import { BootstrapModal, ButtonForm } from './styles';
import api from '~/services/api';
import { useForm } from 'react-hook-form';
import { InputSelect } from './InputSelect';
import { GrFormClose } from 'react-icons/gr';
import { Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemModalSped } from './validations';

interface propsModal {
  enabledModalSped: boolean;
  handleChangeEnabledModal: () => void;
  getValues: any;
  setValue: any;
  register: any;
  setChangeNcmItem: any;
  item: any;
}

interface TabelaCSTItem {
  value: string;
  label: string;
}

const ModalSped: React.FC<propsModal> = ({
  enabledModalSped,
  handleChangeEnabledModal,
  getValues,
  setValue,
  register,
  item,
  setChangeNcmItem,
}) => {
  const {
    register: modalRegister,
    control: modalControl,
    setValue: setValueModal,
    reset: resetModal,
    getValues: getValuesModal,
    setError: setErrorModal,
    clearErrors: clearErrorsModal,
    handleSubmit: handleSubmitModal,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemModalSped),
  });
  const [selectTabela, setSelectTabela] = useState<TabelaCSTItem[]>([]);
  const [codigosPorTabela, setCodigosPorTabela] = useState([]);
  const [clearFields, setClearFields] = useState(false);
  const tabelaCodigoSaida: { [key: string]: string[] } = {
    '02': ['4.3.17', '4.3.10'],
    '03': ['4.3.10'],
    '04': ['4.3.10', '4.3.11'],
    '05': ['4.3.12'],
    '06': ['4.3.13'],
    '07': ['4.3.14'],
    '08': ['4.3.15', '4.3.17'],
    '09': ['4.3.16'],
  };

  const tabelaCST: { [key: string]: string } = {
    '4.3.13': 'Produtos Sujeitos à Alíquota Zero da Contribuição Social',
    '4.3.10':
      'Produtos Sujeitos a Incidência Monofásica da Contribuição Social - Alíquotas Diferenciadas',
    '4.3.11':
      'Produtos Sujeitos a Incidência Monofásica da Contribuição Social - Alíquotas Por Unidade de Medida de Produto',
    '4.3.12':
      'Produtos Sujeitos a Substituição Tributária da Contribuição Social',
    '4.3.15': 'Tabela Operações sem Incidência da Contribuição Social',
    '4.3.17':
      'Tabela Outros Produtos e Operações Sujeitos a Alíquotas Diferenciadas',
    '4.3.16': 'Tabela Operações com Suspensão da Contribuição Social',
    '4.3.14': 'Tabela Operações com Isenção da Contribuição Social',
  };

  const tabelaCSTArray: TabelaCSTItem[] = Object.keys(tabelaCST).map((key) => ({
    value: key,
    label: `Tabela ${key} - ${tabelaCST[key]}`,
  }));

  useEffect(() => {
    setSelectTabela([]);
    const fetchData = async (cod: any) => {
      await fetchSpedCodigos(cod);
    };
    const codTabela = getValues(`cst_saida_${item}`);
    const { des_sped, cod_sped, cod_tabela } = getValues();
    if (codTabela?.value !== undefined) {
      const formataCodTabela: string = codTabela.value
        .toString()
        .padStart(2, '0');
      const codigoTabela = tabelaCodigoSaida[formataCodTabela];
      const tabela: TabelaCSTItem[] = tabelaCSTArray.filter((itemArr) => {
        if (Array.isArray(codigoTabela)) {
          return codigoTabela.includes(itemArr.value);
        }
        return itemArr.value === codigoTabela;
      });
      tabela !== undefined && setSelectTabela(tabela);
      if (tabela.length === 1) {
        setValueModal('des_tab_sped', tabela[0]);
        fetchData(tabela[0].value);
      }
    }
  }, [enabledModalSped]);

  useEffect(() => {
    setValueModal('des_tab_sped', null);
    setValueModal('cod_tab_sped', null);
  }, [getValues(`tipo_nao_pis_cofins_${item}`)]);

  const formataOpcoes = (opcoes: any) => {
    const opcaoFormatada = opcoes.map((itemArr: any) => {
      return {
        value: itemArr.cod_tab_sped.toString().padStart('3', '0'),
        descricao: itemArr.des_tab_sped,
        label: itemArr.descricao,
      };
    });
    return opcaoFormatada;
  };

  const fetchSpedCodigos = async (tabela: string) => {
    try {
      const { data } = await api.post(`/produto/consulta-sped`, {
        cod_tabela: tabela && tabela.toString(),
      });
      const opcoesFormatada = formataOpcoes(data.data);
      setCodigosPorTabela(opcoesFormatada);
    } catch (e) {
      setCodigosPorTabela([]);
    }
  };

  const closeModal = () => {
    setCodigosPorTabela([]);
    setSelectTabela([]);
    setClearFields(true);
    resetModal();

    setTimeout(() => {
      setValueModal('des_tab_sped', null);
      setValueModal('cod_tab_sped', null);
      setClearFields(false);
    }, 100);
    clearErrorsModal();
    handleChangeEnabledModal();
  };

  const handleClear = () => {
    setCodigosPorTabela([]);
    setSelectTabela([]);
    setValue(`des_sped_${item}`, null);
    setValue(`cod_sped_${item}`, null);
    closeModal();
  };

  const handleConfirmAction = handleSubmitModal((data) => {
    const { cod_tab_sped } = data;
    setValue(`des_sped_${item}`, !cod_tab_sped ? null : cod_tab_sped.descricao);
    setValue(
      `cod_sped_${item}`,
      !cod_tab_sped ? null : cod_tab_sped.value.toString().padStart(3, '0'),
    );
    closeModal();
  });

  return (
    <Modal show={enabledModalSped} onHide={closeModal} centered size="lg">
      <BootstrapModal.Header>
        <BootstrapModal.Title>Tabela SPED</BootstrapModal.Title>
        <GrFormClose
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={closeModal}
        />
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div className="row p-2">
          <div className="col-12 col-md-12">
            <InputSelect
              label="Tabela"
              name="des_tab_sped"
              placeholder="Selecione..."
              register={modalRegister}
              control={modalControl}
              options={selectTabela}
              clearCampo={clearFields}
              changeSelected={async (newValue: any) => {
                clearErrorsModal('des_tab_sped');
                clearErrorsModal('cod_tab_sped');
                const oldValue = getValuesModal('des_tab_sped');
                if (newValue.value === oldValue?.value) return;
                setValueModal('des_tab_sped', newValue);
                setValueModal('cod_tab_sped', null);
                setChangeNcmItem(true);
                await fetchSpedCodigos(newValue.value);
              }}
              isError={!!errors.des_tab_sped}
            />
          </div>

          <div className="col-12 col-md-12">
            <InputSelect
              label="Código"
              name="cod_tab_sped"
              placeholder="Selecione..."
              control={modalControl}
              register={register}
              options={codigosPorTabela || []}
              clearCampo={clearFields}
              changeSelected={(newValue: any) => {
                clearErrorsModal('cod_tab_sped');
                setValueModal('cod_tab_sped', newValue);
                setChangeNcmItem(true);
              }}
              isError={!!errors.cod_tab_sped}
            />
          </div>
        </div>
      </BootstrapModal.Body>
      <div className="d-flex justify-content-center aliggn-center w-full mt-2 mb-4">
        <ButtonForm onClick={handleConfirmAction}>Confirmar</ButtonForm>

        <ButtonForm className="limpar" onClick={handleClear}>
          Limpar
        </ButtonForm>
      </div>
      <BootstrapModal.Footer />
    </Modal>
  );
};

export default ModalSped;
