export interface validationInputProps {
  Field: string;
  Invalid: boolean;
}

export interface ResponseTipoItem {
  success: boolean;
  message: string;
  data?: Array<{
    cod_tipo_item: number;
    num_tipo_item: string;
    des_tipo_item: string;
  }>;
  count?: number;
}

export interface TipoUf {
  des_tipo_item: string;
  cod_tipo_item: number;
  num_tipo_item: string;
  flg_nao_pis_cofins: boolean;
  tipo_nao_pis_cofins: number | null;
  flg_est_cred_icms: boolean;
  flg_veda_cred: boolean;
  per_veda_cred_lim: number;
  flg_trib_ent_transf: boolean;
  ufs: Array<{
    index: string;
    des_sigla: string;
    cod_trib_entrada: number;
    cod_tributacao: number;
    per_iva: number;
    per_fcp_icms: number;
    per_fcp_st: number;
    per_veda_cred: number;
  }>;
}

export type MapeamentoValores = {
  '-1': { entrada: string; saida: string };
  '0': { entrada: string; saida: string };
  '1': { entrada: string; saida: string };
  '2': { entrada: string; saida: string };
  '3': { entrada: string; saida: string };
  '4': { entrada: string; saida: string };
  '5': { entrada: string; saida: string };
};

export interface ISelect {
  label: string | undefined;
  value: number | string | undefined;
}

export const tipoPisCofinsEntrada: ISelect[] = [
  {
    value: '50',
    label:
      '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
  },
  {
    value: '51',
    label:
      '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno',
  },
  {
    value: '52',
    label:
      '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação',
  },
  {
    value: '53',
    label:
      '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
  },
  {
    value: '54',
    label:
      '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '55',
    label:
      '55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '56',
    label:
      '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação',
  },
  {
    value: '60',
    label:
      '60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
  },
  {
    value: '61',
    label:
      '61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
  },
  {
    value: '62',
    label:
      '62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação',
  },
  {
    value: '63',
    label:
      '63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
  },
  {
    value: '64',
    label:
      '64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '65',
    label:
      '65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '66',
    label:
      '66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação',
  },
  {
    value: '67',
    label: '67 - Crédito Presumido - Outras Operações',
  },
  {
    value: '70',
    label: '70 - Operação de Aquisição sem Direito a Crédito',
  },
  {
    value: '71',
    label: '71 - Operação de Aquisição com Isenção',
  },
  {
    value: '72',
    label: '72 - Operação de Aquisição com Suspensão',
  },
  {
    value: '73',
    label: '73 - Operação de Aquisição a Alíquota Zero',
  },
  {
    value: '74',
    label: '74 - Operação de Aquisição sem Incidência da Contribuição',
  },
  {
    value: '75',
    label: '75 - Operação de Aquisição por Substituição Tributária',
  },
  {
    value: '98',
    label: '98 - Outras Operações de Entrada',
  },
  {
    value: '99',
    label: '99 - Outras Operações',
  },
];

export const tipoPisCofinsSaida: ISelect[] = [
  {
    value: '01',
    label: '01 - Operação Tributável com Alíquota Básica',
  },
  {
    value: '02',
    label: '02 - Operação Tributável com Alíquota Diferenciada',
  },
  {
    value: '03',
    label:
      '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto',
  },
  {
    value: '04',
    label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
  },
  {
    value: '05',
    label: '05 - Operação Tributável por Substituição Tributária',
  },
  {
    value: '06',
    label: '06 - Operação Tributável a Alíquota Zero',
  },
  {
    value: '07',
    label: '07 - Operação Isenta da Contribuição',
  },
  {
    value: '08',
    label: '08 - Operação sem Incidência da Contribuição',
  },
  {
    value: '09',
    label: '09 - Operação com Suspensão da Contribuição',
  },
  {
    value: '49',
    label: '49 - Outras Operações de Saída',
  },
];
