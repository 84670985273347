import React, { ChangeEvent, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from './styles';
import { AiOutlineSearch } from 'react-icons/ai';

interface InputSpedProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  register: UseFormRegister<FieldValues>;
  showIcon?: boolean;
  control?: Control<FieldValues, any>;
  item: any;
}

export const InputSped: React.FC<InputSpedProps> = ({
  label = 'Tabela SPED',
  register,
  control,
  item,
  ...rest
}) => {
  const handleKeyDown = (event: any) => {
    if ([69, 109, 107, 194, 110].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  return (
    <InputContainer>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <div className="d-flex align-items-center">
          <Controller
            name={`cod_sped_${item}`}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <input
                  {...register(`cod_sped_${item}`)}
                  type="text"
                  disabled
                  className="form-control"
                  style={{
                    maxWidth: '80px',
                  }}
                  placeholder=""
                  onKeyDown={handleKeyDown}
                  value={value || ''}
                  {...rest}
                />
              );
            }}
          />
          <Controller
            name={`des_sped_${item}`}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <input
                  {...register(`des_sped_${item}`)}
                  type="text"
                  disabled
                  className="form-control"
                  style={{ marginLeft: '2px' }}
                  placeholder=""
                  onKeyDown={handleKeyDown}
                  value={value || ''}
                  {...rest}
                />
              );
            }}
          />
        </div>
      </Form.Group>
    </InputContainer>
  );
};
