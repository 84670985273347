import * as yup from 'yup';

export const schema = yup
  .object({
    num_ncm: yup.string().required(),
    num_des_ncm: yup.string().required(),
    num_excecao_tipi: yup.number().nullable(true),
    per_veda_cred_lim: yup.string().when('flg_veda_cred', {
      is: (value: any) => {
        if (value === 0) return true;
        return false;
      },
      then: yup.string().required(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
  })
  .required();

export const ufValidate = yup.object({
  ufItem: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .required(),
  tribEntradaItem: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .required(),
  tribSaidaItem: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .required(),
  cod_tipo: yup.number().nullable(),
  iva: yup.string(),
  vedCred: yup.string(),
  fcpSaida: yup.string(),
});
