import { lighten } from 'polished';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  position: relative;
  .novo {
    align-items: center;
    margin-top: 0.3rem;
  }

  .input-container {
    position: relative;
    align-items: center;
    .min-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      height: 2.3rem;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }

  section {
    width: 100%;
  }
  .nav-tabs {
    display: flex;
    margin: 0;
  }
  #DropDownWidth {
    .react-select__menu {
      position: absolute;
      width: 400px;
      z-index: 9999;
    }
  }

  .nav-link {
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 400;
    border-top-right-radius: 6px;
    border-radius: 6px 6px 0px 0px;

    a {
      color: #666666;
      text-decoration: none;
    }

    button {
      background-color: ${({ theme: { colors } }) => colors.danger};
      border: none;
      color: ${({ theme: { colors } }) => colors.white};
      margin-left: 10px;
      opacity: 0;
      animation: opacity 0.35s ease-in-out;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 12px;
      }

      &:focus {
        outline: none;
      }
    }

    &:hover {
      button {
        opacity: 1;
      }
      background-color: ${({ theme: { colors } }) =>
    lighten(0.75, colors.primary)};
    }
    &.active a {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
      background-color: ${({ theme }) => theme.colors.white};
      left: -1px;
    }
  }
  #myTabUf {
    margin: 0;
    width: 100%;
    padding: 0;
  }
  #tabUFContent {
    position: relative !important;
    display: flex !important;
    align-itens: center;
    width: 100% !important;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
  .tab-pane {
    position: relative;
    width: 100%;
    padding: 0px;
    .elements {
      padding: 0px;
      margin: 0;
      margin-left: 6px;
      width: 100%;
    }
  }
  .elements {
    padding: 0px;
    margin: 0;
    margin-left: 15px;
    width: 98.2%;
  }
  .inpunts {
    z-index: 100;
  }

  .containerInputPercente {
    .form-group {
      margin-top: 0px !important;
      .form-label {
        display: none;
      }
    }
  }

  label[data-checked] {
    background-color: #8850bf !important;
    color: #ffffff;
  }
`;
export const containerPanel = styled.section`
  width: 100%;
  background: red;
  padding: 0;
  margin: 0;
`;

export const LabelDoFildset = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  width: 160px;
  height: 40px;
  margin: 2px;
  font-weight: 500;
  border-right: 1px solid #dfe1e6;
  @media only screen and (min-width: 1200px) {
    :last-child {
      border: none;
    }
  }
  span {
    display: flex;
    flex-direction: row;
    font-size: 12pt;
    gap: 5px;
    div {
      font-weight: 400;
    }
  }
`;
export const SearchNCM = styled.label`
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  width: 100%;
  padding: 10px;
  border: 1px solid #dfe1e6;
  align-items: center;
  border-radius: 5px;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  input {
    border: none;
  }
  svg {
    height: 20px;
    width: 20px;
  }
  button {
    display: flex;
    background: none;
    border: none;
    align-items: center;
  }
`;
export const LabelDoFildsetContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
  width: 100%;
  min-width: 320px;
  label {
    margin-right: 5px;
  }
`;

export const FildsetContainer = styled.section`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;
export const ContainerRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60px;
  font-size: 1rem;
  font-weight: 400;
`;

export const GroupItem = styled.div`
  display: grid;
  width: 100%;
  margin-top: 5px;

  grid-template-columns: auto auto 1fr;
  grid-template-rows: 1fr;
  white-space: nowrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const GroupItens = styled.div`
  display: grid;
  width: 100%;
  margin-top: 5px;

  grid-template-columns: auto auto auto 120px;
  grid-template-rows: 1fr;
  white-space: nowrap;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
`;
export const ContainerLoader = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  color: #000;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: #504e4e;
    box-shadow: none !important;
  }
`;
